import { CgSpinner } from "react-icons/cg";

import cn from "../../util/cn.js";

interface Props {
  className?: string;
  size?: "xs" | "sm" | "md" | "lg";
}

const Spinner = ({ className, size = "md" }: Props) => (
  <div className={cn("flex items-center justify-center p-4", className)}>
    <CgSpinner
      className={cn("animate-spin  opacity-70", {
        "text-sm": size === "xs",
        "text-base": size === "sm",
        "text-2xl": size === "md",
        "text-4xl": size === "lg",
      })}
    />
  </div>
);

export default Spinner;
